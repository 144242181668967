import React, { Component } from "react";
import { connect } from "react-redux";
import BarGraphCard from "../components/BarGraphCard";
import * as actions from "../../app/modules/Hamropalika/_redux/WardRedux";

class Sanitation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			barData: [],
		};
	}
	componentDidMount() {
		this.props.dispatch(actions.fetchWaterResource());
		this.props.dispatch(actions.fetchCookingSource());
		this.props.dispatch(actions.fetchElectricSource());
		this.props.dispatch(actions.fetchToilet());
		this.props.dispatch(actions.fetchGarbage());
	}
	componentWillReceiveProps(nextProps) {
		if (
			nextProps.detail.selectedWaterResource !==
				this.props.detail.selectedWaterResource ||
			nextProps.detail.selectedLanguage !== this.props.detail.selectedLanguage
		) {
			let totalData = nextProps.detail.selectedWaterResource?.data?.map(
				({ value }) => value
			);
			let labelData = nextProps.detail.selectedWaterResource?.data?.map(
				(datum) => {
					return datum[nextProps.detail.selectedLanguage];
				}
			);
			let waterResourceData = {
				id: 1,
				values: [
					{
						name: "",
						data: totalData,
					},
				],
				title:
					nextProps.detail.waterResourceList?.title[
						nextProps.detail.selectedLanguage
					],
				categories: labelData,
				detail: "WaterResource",
				color: "#BB371A",
				xaxis: "Source of water",
				xaxis_nepali: "खानेपानीको मुख्य श्रोत",
			};
			this.setState((previousState) => {
				let barData = previousState.barData;
				let prevWardData = previousState.barData.find((i) => i.id == 1);
				if (!prevWardData) {
					barData.push(waterResourceData);
				} else {
					let prevWardDataIndex = previousState.barData.indexOf(prevWardData);
					barData[prevWardDataIndex] = waterResourceData;
				}
				return {
					barData,
				};
			});
		}
		if (
			nextProps.detail.selectedCookingSource !==
				this.props.detail.selectedCookingSource ||
			nextProps.detail.selectedLanguage !== this.props.detail.selectedLanguage
		) {
			let totalData = nextProps.detail.selectedCookingSource?.data?.map(
				({ value }) => value
			);
			let labelData = nextProps.detail.selectedCookingSource?.data?.map(
				(datum) => {
					return datum[nextProps.detail.selectedLanguage];
				}
			);
			let cookingData = {
				id: 2,
				values: [
					{
						name: "",
						data: totalData,
					},
				],
				title:
					nextProps.detail.cookingSourceList?.title[
						nextProps.detail.selectedLanguage
					],
				categories: labelData,
				detail: "CookingSource",
				color: "#0A1931",
				xaxis: "Fuel source for cooking",
				xaxis_nepali: "चुलो को मुख्य श्रोत",
			};
			this.setState((previousState) => {
				let barData = previousState.barData;
				let prevWardData = previousState.barData.find((i) => i.id == 2);
				if (!prevWardData) {
					barData.push(cookingData);
				} else {
					let prevWardDataIndex = previousState.barData.indexOf(prevWardData);
					barData[prevWardDataIndex] = cookingData;
				}
				return {
					barData,
				};
			});
		}
		if (
			nextProps.detail.selectedElectricSource !==
				this.props.detail.selectedElectricSource ||
			nextProps.detail.selectedLanguage !== this.props.detail.selectedLanguage
		) {
			let totalData = nextProps.detail.selectedElectricSource?.data?.map(
				({ value }) => value
			);
			let labelData = nextProps.detail.selectedElectricSource?.data?.map(
				(datum) => {
					return datum[nextProps.detail.selectedLanguage];
				}
			);
			let electricData = {
				id: 3,
				values: [
					{
						name: "",
						data: totalData,
					},
				],
				title:
					nextProps.detail.electricSourceList?.title[
						nextProps.detail.selectedLanguage
					],
				categories: labelData,
				detail: "ElectricSource",
				color: "#4A1C40",
				xaxis: "Source of electricity",
				xaxis_nepali: "वत्तिको श्रोत",
			};
			this.setState((previousState) => {
				let barData = previousState.barData;
				let prevWardData = previousState.barData.find((i) => i.id == 3);
				if (!prevWardData) {
					barData.push(electricData);
				} else {
					let prevWardDataIndex = previousState.barData.indexOf(prevWardData);
					barData[prevWardDataIndex] = electricData;
				}
				return {
					barData,
				};
			});
		}
		if (
			nextProps.detail.selectedToilet !== this.props.detail.selectedToilet ||
			nextProps.detail.selectedLanguage !== this.props.detail.selectedLanguage
		) {
			let totalData = nextProps.detail.selectedToilet?.data?.map(
				({ value }) => value
			);
			let labelData = nextProps.detail.selectedToilet?.data?.map((datum) => {
				return datum[nextProps.detail.selectedLanguage];
			});
			let toiletData = {
				id: 4,
				values: [
					{
						name: "",
						data: totalData,
					},
				],
				title:
					nextProps.detail.toiletList?.title[nextProps.detail.selectedLanguage],
				categories: labelData,
				detail: "Toilet",
				color: "#064420",
				xaxis: "Type of toilet",
				xaxis_nepali: "शौचालयको  किसिम",
			};
			this.setState((previousState) => {
				let barData = previousState.barData;
				let prevWardData = previousState.barData.find((i) => i.id == 4);
				if (!prevWardData) {
					barData.push(toiletData);
				} else {
					let prevWardDataIndex = previousState.barData.indexOf(prevWardData);
					barData[prevWardDataIndex] = toiletData;
				}
				return {
					barData,
				};
			});
		}
		if (
			nextProps.detail.selectedGarbage !== this.props.detail.selectedGarbage ||
			nextProps.detail.selectedLanguage !== this.props.detail.selectedLanguage
		) {
			let totalData = nextProps.detail.selectedGarbage?.data?.map(
				({ value }) => value
			);
			let labelData = nextProps.detail.selectedGarbage?.data?.map((datum) => {
				return datum[nextProps.detail.selectedLanguage];
			});
			let garbageData = {
				id: 5,
				values: [
					{
						name: "",
						data: totalData,
					},
				],
				title:
					nextProps.detail.garbageList?.title[
						nextProps.detail.selectedLanguage
					],
				categories: labelData,
				detail: "Garbage",
				color: "#8E9775",
				xaxis: "Waste management types",
				xaxis_nepali: "फोहोरमैला व्यवस्थापनको किसिम",
			};
			this.setState((previousState) => {
				let barData = previousState.barData;
				let prevWardData = previousState.barData.find((i) => i.id == 5);
				if (!prevWardData) {
					barData.push(garbageData);
				} else {
					let prevWardDataIndex = previousState.barData.indexOf(prevWardData);
					barData[prevWardDataIndex] = garbageData;
				}
				return {
					barData,
				};
			});
		}
	}
	render() {
		return (
			<div className="row">
				{this.state.barData?.map((datum, index) => {
					return (
						<BarGraphCard key={index} datum={datum} section="sanitation_bar_" />
					);
				})}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	detail: state.ward,
});
export default connect(mapStateToProps)(Sanitation);

import React, { Component, useState } from "react";
import { Checkbox, FormControlLabel, FormGroup, Grid } from "@material-ui/core";
import { infastructure, gaupalika, ward } from "../../variable/global";
import Axios from "../../redux/axiosHandlerGeo";
import { totalWard } from "../../variable/config";
import { MapContainer, TileLayer, LayersControl, GeoJSON } from "react-leaflet";
import GeoJsonLayer from "../sections/GeoJsonLayer";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { connect } from "react-redux";
import {BiAccessibility, BiCaretDown} from 'react-icons/bi';
import {AiOutlineMenu} from 'react-icons/ai'
import {BsSearch} from 'react-icons/bs';
import '../../assets/css/MapDetailPage/sidebar.css'

class MapDetailPage extends Component{
// 	const [sidebar, setSidebar] = useState(true);
// const showSidebar = () => setSidebar(!sidebar);

  constructor(props) {
		super(props);
		this.state = {
			barOpen: true,
			navOpen: false,
			navOpen0: false,
			navOpen1: false,
			navOpen2: false,
			navOpen3: false,
			navOpen4: false,
			navOpen5: false,
			navOpen6: false,
			navOpen7: false,
			navOpen8: false,
			navOpen9: false,
			navOpen10: false,
			navOpen11: false,
			modalShow: false,
			value: props.value,
			boundData: [],
			location: {
				lat: 27.938571,
				lng: 85.918619,
			},
			zoom: 11,
			checkbox: {
				economic: false,
			},
			wholeGeoJSON: {},
			showGeoJSON: [],
			wardCheckAll: false,
			indeterminateWard: false,
			// wardSelected: []
			wardSelected: [...Array(totalWard).keys()].map((each) => each + 1),
		};
		this.getMunicpalId = this.getMunicpalId.bind(this);
	}

	componentDidMount() {
		// setTimeout(() => {
		// 	let evt = {
		// 		target: {
		// 			checked: true,
		// 		},
		// 	};
		this.arrangeAllOption();
		// }, 2000);
	}

  isGeoJsonAlreadyFetched = (field) => {
		const { wholeGeoJSON } = this.state;
		//console.log("Geo json field", field);
		//console.log("Geo json", wholeGeoJSON);
		//console.log("Geo json fetched", wholeGeoJSON[field]);
		return Boolean(field in wholeGeoJSON);
	};

  fetchGeoJSON = (changedData) => {
		//console.log("fetch", changedData);
		const body = {
			ward: "fetch/ward",
			// private: "institute/private",
			// community: "institute/community",
			// madrasa: "institute/madrasa",
			montessori: "institute/montessori",
			primary: "institute/primary",
			secondary: "institute/secondary",
			basic: "institute/basic",
			gumba: "institute/gumba",
			college_nontechnical: "institute/college_nontechnical",
			college_technical: "institute/college_technical",
			university: "institute/university",
			skill_center: "institute/skill_center",
			hospital: "institute/hospital",
			healthPost: "institute/healthPost",
			swasthya_ikaai: "institute/swasthya_ikaai",
			primary_heath_center: "institute/primary_heath_center",
			basic_health_center: "institute/basic_health_center",
			urban_helath_center: "institute/urban_helath_center",
			village_clinic: "institute/village_clinic",
			helpdesk: "institute/helpdesk",
			maternity: "institute/maternity",
			clinic: "institute/clinic",
			ayurved: "institute/ayurved",
			natural_hospital: "institute/natural_hospital",
			bridge: "institute/bridge",
			stadium: "institute/stadium",
			powerhouse: "institute/powerhouse",
			community_building: "institute/community_building",
			powerstation: "institute/powerstation",
			airport: "institute/airport",
			fm_station: "institute/fm_station",
			tv_station: "institute/tv_station",
			tower: "institute/tower",
			internet_provider: "institute/internet_provider",
			calvert: "institute/calvert",
			water_tank: "institute/water_tank",
			sabhaa_hal: "institute/sabhaa_hal",
			sabdahaa_sthal_1: "institute/sabdahaa_sthal_1",
			animal_cut: "institute/animal_cut",
			a: "institute/a",
			b: "institute/b",
			c: "institute/c",
			d: "institute/d",
			public_toilet: "institute/public_toilet",
			free_wifi: "institute/free_wifi",
			buspark: "institute/buspark",
			park: "institute/park",
			chautaara: "institute/chautaara",
			paati_pauwaa: "institute/paati_pauwaa",
			zoo: "institute/zoo",
			land_revenue: "institute/land_revenue",
			dao: "institute/dao",
			court: "institute/court",
			ministries: "institute/ministries",
			electricity: "institute/electricity",
			post_office: "institute/post_office",
			CUSTOOM_OFFICE: "institute/CUSTOOM_OFFICE",
			dcc: "institute/dcc",
			food_kyaarentaain: "institute/food_kyaarentaain",
			other_government: "institute/other_government",
			ngo: "institute/ngo",
			private: "institute/private",
			ingo: "institute/ingo",
			brick_industry: "institute/brick_industry",
			sugar_mill: "institute/sugar_mill",
			mill: "institute/mill",
			kaagaj_udhog: "institute/kaagaj_udhog",
			other_industry: "institute/other_industry",
			police: "institute/police",
			apf: "institute/apf",
			army: "institute/army",
			traffic: "institute/traffic",
			mining: "institute/mining",
			waterfall: "institute/waterfall",
			cave: "institute/cave",
			lake: "institute/lake",
			pond: "institute/pond",
			main_water_source: "institute/main_water_source",
			others: "institute/others",
			temple: "institute/temple",
			mosque: "institute/mosque",
			gumba: "institute/gumba",
			charch: "institute/charch",
			dharmasala: "institute/dharmasala",
			hotwater: "institute/hotwater",
			museum: "institute/museum",
			palace: "institute/palace",
			cultural_club: "institute/cultural_club",
			forest: "institute/forest",
			homehotell: "institute/homehotell",
			resort: "institute/resort",
			homesty: "institute/homesty",
			resturent: "institute/resturent",
			palika_related_office: "institute/palika_related_office",
		};

		return new Promise((resolve, reject) => {
			// //console.log("axiosUpper", body[changedData]);

			Axios.get(`/api/geojson/${body[changedData]}`)
				.then((result) => {
					let wholeGeoJSON = this.state.wholeGeoJSON;
					// wholeGeoJSON[changedData] = result.data;
					this.setState(
						{
							wholeGeoJSON: {
								...wholeGeoJSON,
								[changedData]: result.data,
							},
						},
						this.arrangeAllOption
					);
				})
				.catch((err) => reject());
		});
	};

  arrangeAllOption = async () => {
		const { checkbox, wardSelected, wardCheckAll } = this.state;
		//console.log("State:", this.state);
		//console.log("Ward selected", wardSelected);
		const showGeoJSON = [];
		console.log("Wholegeojson", this.state.wholeGeoJSON);
		if (!this.isGeoJsonAlreadyFetched("ward")) await this.fetchGeoJSON("ward");
		showGeoJSON.push(
			wardSelected.map((each) => this.state.wholeGeoJSON.ward[each - 1])
		);

		let dataToCheck = [];

		dataToCheck = dataToCheck.concat(infastructure);
		//console.log("dataToCheck", dataToCheck);
		const data = dataToCheck.map(async (each, i) => {
			if (each.children) {
				each.children.map(async (child) => {
					if (checkbox[child.value]) {
						//console.log("Checkbox", checkbox[child.value]);
						if (!this.isGeoJsonAlreadyFetched(child.value))
							await this.fetchGeoJSON(child.value);
						if (wardCheckAll)
							showGeoJSON.push(this.state.wholeGeoJSON[child.value]);
						else
							wardSelected.map((eachWard) => {
								//console.log("ewar", eachWard);
								showGeoJSON.push(
									Object.values(this.state.wholeGeoJSON[child.value]).filter(
										(eachType) => eachType.wardNo === eachWard
									)
								);
							});
							console.log("ShowGeojson", showGeoJSON);
					}
				});
			} else {
				if (checkbox[each.value]) {
					//console.log("Checkbox", checkbox[each.value]);
					if (!this.isGeoJsonAlreadyFetched(each.value))
						await this.fetchGeoJSON(each.value);

					if (wardCheckAll)
						showGeoJSON.push(this.state.wholeGeoJSON[each.value]);
					else
						wardSelected.map((eachWard) => {
							showGeoJSON.push(
								this.state.wholeGeoJSON[each.value].filter(
									(eachType) => eachType.wardNo === eachWard
								)
							);
						});
				}
			}
		});
		// wait until all promises resolve
		await Promise.all(data);
		this.setState({ showGeoJSON });
	};

	onCheckAllWardChange = (e) => {
		this.setState((prev) => {
			return {
				wardSelected: !prev.wardCheckAll
					? [...Array(totalWard).keys()].map((each) => each + 1)
					: [],
				indeterminateWard: false,
				wardCheckAll: !prev.wardCheckAll,
			};
		}, this.arrangeAllOption);
	};

  handleWardChange = (checkedValue) => {
		// const checkedValue = e.target.value;
		let wardSelected;
		let checkedValueInt = parseInt(checkedValue);
		if (this.state.wardSelected.includes(checkedValueInt)) {
			wardSelected = this.state.wardSelected.filter(
				(i) => i !== checkedValueInt
			).sort();
		} else {
			wardSelected = [...this.state.wardSelected, checkedValueInt].sort();
		}
		//console.log("wardarr", wardSelected);
		this.setState(
			{
				wardSelected,
				indeterminateWard:
					!!checkedValue.length &&
					checkedValue.length > 0 &&
					checkedValue.length < totalWard,
				wardCheckAll: checkedValue.length === totalWard,
			},
			this.arrangeAllOption
		);
		//console.log(this.state.wardSelected);
	};

	handleChange = (e) => {
		e.persist();
		console.log("event", e.target.name);
		// e.persist();
		this.setState(
			({ checkbox }) => ({
				checkbox: {
					...checkbox,
					[e.target.name]: e.target.checked,
				},
			}),
			this.arrangeAllOption
		);
		//console.log(this.state);
	};

  getMunicpalId() {
		return (
			<div className="big-map">
				<MapContainer
					style={{ height: "100vh" }}
					center={this.state.location}
					zoom={this.state.zoom}
					minZoom="11"
				>
					<TileLayer
						url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
						attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
					/>

					{/* {this.state.showGeoJSON.length > 0 &&
						this.state.showGeoJSON.map((each) => {
							return (
								each.length > 0 &&
								Object.values(each).map((eachGeoJSON, index) => {
									if (eachGeoJSON)
										return ( */}
											<GeoJsonLayer
												showGeoJSON={this.state.showGeoJSON}
											/>
										{/* );
								})
							);
						})} */}
				</MapContainer>
			</div>
		);
	}

  getDropdownIcon = (icon) => {
		return (
			<img
				className="mr-3"
				src={toAbsoluteUrl(`/media/hamropalika/png-icons/${icon}.png`)}
				alt=""
			/>
		);
	};

	getWardDropDown = () => {
		return (
			<>
				<div className={this.state.navOpen ? "sidebar-item open" : "sidebar-item"}>
					<div className='sidebar-title' onClick={() => this.setState({ navOpen:this.state.navOpen === true? false : true})}>
						<span>
							{/* {this.props.selectedLanguage == "nepali" ? "वडा" : "Ward"} */}
							{"वडा"}
						</span>
						<BiCaretDown  className='toogle-button'/>
					</div>
					<div className='sidebar-content'>
					{ward.map((each) => 
					<FormControlLabel className="sidebar-item plain"
					control={
						<Checkbox
							checked={this.state.wardSelected.includes(
								parseInt(each.value)
							)}
							value={parseInt(each.value)}
							onChange={(e) => this.handleWardChange(e.target.value)}
						/>
					}
					label={each['nepali']}
				/>
					) }
					</div>
				</div>
			</>
		);
	};

	getDropdownContent = () => {
		return infastructure.map((category, index) => {
			let navOpen = "navOpen"+index;
			return (
				<>
				{/* {category.icon && this.getDropdownIcon(category.icon)} */}
				{!category.value && (
					<div>
					<div className={this.state[`${navOpen}`]? "sidebar-item"+index+" open" : "sidebar-item"+index}>
						<div className='sidebar-title' onClick={() => this.setState({ [`${navOpen}`] : this.state[`${navOpen}`] === true? false : true})}>
							<span>
								{category.nepali}
								{/* {this.props.selectedLanguage == "nepali"
											? category.nepali
											: category.english} */}
							</span>
							<BiCaretDown  className='toogle-button'/>
						</div>
						<div className='sidebar-content'>
						{category.children && category.children.map((child) => (
							<FormControlLabel className="sidebar-item plain"
							control={
								<Checkbox
									name={child.value}
									onChange={(e) => this.handleChange(e)}
									checked={this.state.checkbox[child.value]}
									value="checkedA"
								/>
							}
							label={child['nepali']}
						/>
						))}
						</div>
					</div>
					</div>
				)}
				{category.value && (
					<FormControlLabel className="sidebar-item plain"
					control={
						<Checkbox
							name={category.value}
							onChange={(e) => this.handleChange(e)}
							checked={this.state.checkbox[category.value]}
							value="checkedA"
						/>
					}
					label={category['nepali']}
					/>
				)}
				</>
			);
		});
	};

  render() {
	return (
    	<>
		<div className="map-content">
			<div className="header-map">
				<div id="map-headerLeft">
					<a href="#" className='toggle-bars'  onClick={() => this.setState({ barOpen:this.state.barOpen === true? false : true})}> <AiOutlineMenu  className="bars"/> </a>
					<div id="map-headerText">
						<h3 className="text-white">भोटेकोशी गाउँपालिका</h3>
						<p className="text-white d-none d-md-block">
							<strong>सिन्धुपाल्चोक, बागमती प्रदेश, नेपाल</strong>
						</p>
					</div>
					
				</div>
				
				
				<div className="search-bar">
					<input
					type="text"
					name="text"
					placeholder="Search..."
					value={this.state.value} autoFocus onChange={(event) => {this.setState({ value: event.target.value })}}
					className="search"
					/>
					<button type="submit" className="search-button">
						<BsSearch />
					</button>
				</div>
                <div>

				</div>
			</div>
			<div className={this.state.barOpen? "sidebar active" : "sidebar"}>
				{this.getWardDropDown()}
				{this.getDropdownContent()}
			</div>
			{this.getMunicpalId()}
		</div>
		
    	</>
	)};
}

const mapStateToProps = (state) => ({
	selectedLanguage: state.ward.selectedLanguage,
});
export default connect(mapStateToProps)(MapDetailPage);


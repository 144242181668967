import React, { Component } from "react";
import Axios from "../../redux/axiosHandlerGeo";
import { Card } from "react-bootstrap";
import PersonCard from "../components/PersonCard";

class Representative extends Component {
	constructor(props) {
		super(props);
		this.state = {
			people: [],
		};
	}
	componentDidMount() {
		Axios.get("/api/staff/mainPage")
			.then((res) => {
				console.log("staff", res.data);
				// const csvData = this.getCSVData(res.data);
				this.setState({
					people: res.data,
					// csvData,
				});
			})
			.catch((err) => {
				console.log("dataSurvey err", err);
			});

		// const people = [
		// 	{
		// 		name: "पासाङ नुर्पु शेर्पा",
		// 		nameEnglish: "Pasang Nurpu Sherpa",
		// 		designation: "गाउँपालिका अध्यक्ष",
		// 		designationEnglish: "President",
		// 		email: "N/A",
		// 		phone: "N/A",
		// 		phoneEnglish: "N/A",
		// 		address: "सिन्धुपाल्चोक, बागमती प्रदेश, नेपाल",
		// 		addressEnglish: "Sindulipalchowk rural municipality",
		// 		image: "media/hamropalika/people/st1.jpg",
		// 		containerClass: "col-sm-6 col-md-12 col-xl-6",
		// 	},
		// 	{
		// 		name: "दिछेन शेर्पा",
		// 		nameEnglish: "Dichhen Sherpa",
		// 		designation: "गाउँपालिका उपाध्यक्ष",
		// 		designationEnglish: "Vice-chariperson",
		// 		email: "N/A",
		// 		phone: "N/A",
		// 		phoneEnglish: "N/A",
		// 		address: "सिन्धुपाल्चोक, बागमती प्रदेश, नेपाल",
		// 		addressEnglish: "Sindulipalchowk rural municipality",
		// 		image: "media/hamropalika/people/st4.jpg",
		// 		containerClass: "col-sm-6 col-md-12 col-xl-6",
		// 	},
		// ];
		// this.setState({ people });
	}
	render() {
		return (
			<div className="row h-100">
				{this.state.people.map((person, index) => {
					return <PersonCard data={person} key={index} />;
				})}
			</div>
		);
	}
}

export default Representative;

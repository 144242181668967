import React, { Component } from "react";
import BarGraphCard from "../components/BarGraphCard";
import PieChartCard from "../components/PieChartCard";
import PieChartProgressCard from "../components/PieChartProgressCard";
import { connect } from "react-redux";
import * as actions from "../../app/modules/Hamropalika/_redux/WardRedux";
class Buildings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			barData: [],
			surveyData: [],
		};
	}
	componentDidMount() {
		this.props.dispatch(actions.fetchHouseCertificate());
		this.props.dispatch(actions.fetchHouseMapPass());
		this.props.dispatch(actions.fetchHouseCount());
		this.props.dispatch(actions.fetchRoofType());
		this.props.dispatch(actions.fetchHouseFoundation());
	}
	componentWillReceiveProps(nextProps) {
		if (nextProps.detail !== this.props.detail) {
			let surveyData = [
				{
					id: 1,
					data: nextProps.detail.selectedHouseCertificate?.data.map(
						({ value }) => value
					),
					containerClass: "col-md-6 col-lg-4",
					labels: nextProps.detail.selectedHouseCertificate?.data.map(
						({ value }) => value
					),
					labelDesc: nextProps.detail.selectedHouseCertificate?.data.map(
						(datum) => {
							return datum[nextProps.detail.selectedLanguage];
						}
					),
					title:
						nextProps.detail.houseCertificateList.title[
							nextProps.detail.selectedLanguage
						],
					color: "success",
					value: nextProps.detail.houseCertificateList.percentage,
				},
				{
					id: 2,
					data: nextProps.detail.selectedHouseMapPass?.data.map(
						({ value }) => value
					),
					containerClass: "col-md-6 col-lg-4",
					labels: nextProps.detail.selectedHouseMapPass?.data.map(
						({ value }) => value
					),
					labelDesc: nextProps.detail.selectedHouseMapPass?.data.map(
						(datum) => {
							return datum[nextProps.detail.selectedLanguage];
						}
					),
					title:
						nextProps.detail.houseMapPassList.title[
							nextProps.detail.selectedLanguage
						],
					color: "success",
					value: nextProps.detail.houseMapPassList.percentage,
				},
			];
			this.setState({ surveyData });
		}

		if (
			nextProps.detail.selectedHouseCount !==
				this.props.detail.selectedHouseCount ||
			nextProps.detail.selectedLanguage !== this.props.detail.selectedLanguage
		) {
			let totalData = nextProps.detail.selectedHouseCount?.data.map(
				({ value }) => value
			);
			let labelData = nextProps.detail.selectedHouseCount?.data.map((datum) => {
				return datum[nextProps.detail.selectedLanguage];
			});
			let houseCountData = {
				id: 1,
				values: [
					{
						name: "",
						data: totalData,
					},
				],
				title:
					nextProps.detail.houseCountList.title[
						nextProps.detail.selectedLanguage
					],
				categories: labelData,
				detail: "HouseCount",
				color: "#C490E4",
				xaxis: "Number of houses",
				xaxis_nepali: "घर संख्या",
			};
			this.setState((previousState) => {
				let barData = previousState.barData;
				let prevWardData = previousState.barData.find((i) => i.id == 1);
				if (!prevWardData) {
					barData.push(houseCountData);
				} else {
					let prevWardDataIndex = previousState.barData.indexOf(prevWardData);
					barData[prevWardDataIndex] = houseCountData;
				}
				return {
					barData,
				};
			});
		}
		if (
			nextProps.detail.selectedRoofType !==
				this.props.detail.selectedRoofType ||
			nextProps.detail.selectedLanguage !== this.props.detail.selectedLanguage
		) {
			let totalData = nextProps.detail.selectedRoofType?.data.map(
				({ value }) => value
			);
			let labelData = nextProps.detail.selectedRoofType?.data.map((datum) => {
				return datum[nextProps.detail.selectedLanguage];
			});
			let roofTypeData = {
				id: 2,
				values: [
					{
						name: "",
						data: totalData,
					},
				],
				title:
					nextProps.detail.roofTypeList.title[
						nextProps.detail.selectedLanguage
					],
				categories: labelData,
				detail: "RoofType",
				color: "#01937C",
				xaxis: "Type of roof",
				xaxis_nepali: "घरको छानाको बनावट",
			};
			this.setState((previousState) => {
				let barData = previousState.barData;
				let prevWardData = previousState.barData.find((i) => i.id == 2);
				if (!prevWardData) {
					barData.push(roofTypeData);
				} else {
					let prevWardDataIndex = previousState.barData.indexOf(prevWardData);
					barData[prevWardDataIndex] = roofTypeData;
				}
				return {
					barData,
				};
			});
		}
		if (
			nextProps.detail.selectedHouseFoundation !==
				this.props.detail.selectedHouseFoundation ||
			nextProps.detail.selectedLanguage !== this.props.detail.selectedLanguage
		) {
			let totalData = nextProps.detail.selectedHouseFoundation?.data.map(
				({ value }) => value
			);
			let labelData = nextProps.detail.selectedHouseFoundation?.data.map(
				(datum) => {
					return datum[nextProps.detail.selectedLanguage];
				}
			);
			let houseFoundationData = {
				id: 3,
				values: [
					{
						name: "",
						data: totalData,
					},
				],
				title:
					nextProps.detail.houseFoundationList.title[
						nextProps.detail.selectedLanguage
					],
				categories: labelData,
				detail: "HouseFoundation",
				color: "#4A503D",
				xaxis: "Type of house",
				xaxis_nepali: "घरको बनावट",
			};
			this.setState((previousState) => {
				let barData = previousState.barData;
				let prevWardData = previousState.barData.find((i) => i.id == 3);
				if (!prevWardData) {
					barData.push(houseFoundationData);
				} else {
					let prevWardDataIndex = previousState.barData.indexOf(prevWardData);
					barData[prevWardDataIndex] = houseFoundationData;
				}
				return {
					barData,
				};
			});
		}
	}
	render() {
		return (
			<div className="row">
				{this.state.barData.map((datum, index) => {
					return (
						<BarGraphCard
							key={index}
							datum={datum}
							section="buildings_bar_graph"
						/>
					);
				})}
				{this.state.surveyData.map((data, index) => {
					return (
						<PieChartCard
							data={data}
							key={index}
							section="buildings_pie_chart_"
						/>
					);
				})}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	detail: state.ward,
});
export default connect(mapStateToProps)(Buildings);

import React, { Component } from "react";
import { connect } from "react-redux";
import BarGraphCard from "../components/BarGraphCard";
import * as actions from "../../app/modules/Hamropalika/_redux/WardRedux";

class Education extends Component {
	constructor(props) {
		super(props);
		this.state = {
			barData: [],
		};
	}
	componentDidMount() {
		this.props.dispatch(actions.fetchEducationSector());
		this.props.dispatch(actions.fetchEducation());
	}
	componentWillReceiveProps(nextProps) {
		let femaleTitle =
			nextProps.detail.selectedLanguage == "nepali" ? "महिला" : "Female";
		let maleTitle =
			nextProps.detail.selectedLanguage == "nepali" ? "पुरुष" : "Male";
		let thirdTitle =
			nextProps.detail.selectedLanguage == "nepali" ? "अन्य" : "Other";
		// You don't have to do this check first, but it can help prevent an unneeded
		if (
			nextProps.detail.selectedEducationSector !==
				this.props.detail.selectedEducationSector ||
			nextProps.detail.selectedLanguage !== this.props.detail.selectedLanguage
		) {
			let femaleData = nextProps.detail.selectedEducationSector?.data.female?.map(
				({ value }) => value
			);
			let maleData = nextProps.detail.selectedEducationSector?.data.male?.map(
				({ value }) => value
			);
			let thirdData = nextProps.detail.selectedEducationSector?.data.third?.map(
				({ value }) => value
			);
			let labelData = nextProps.detail.selectedEducationSector?.data.third?.map(
				(datum) => {
					return datum[nextProps.detail.selectedLanguage];
				}
			);
			let femaleTitle =
				nextProps.detail.selectedLanguage == "nepali" ? "महिला" : "Female";
			let maleTitle =
				nextProps.detail.selectedLanguage == "nepali" ? "पुरुष" : "Male";
			let thirdTitle =
				nextProps.detail.selectedLanguage == "nepali" ? "अन्य" : "Other";
			let educationSectorData = {
				id: 1,
				values: [
					{
						name: femaleTitle,
						data: femaleData,
					},
					{
						name: maleTitle,
						data: maleData,
					},
					{
						name: thirdTitle,
						data: thirdData,
					},
				],
				title:
					nextProps.detail.educationSectorList.title[
						nextProps.detail.selectedLanguage
					],
				categories: labelData,
				detail: "EducationSector",
				xaxis: "Education level",
				xaxis_nepali: "शिक्षा स्तर",
			};
			this.setState((previousState) => {
				let barData = previousState.barData;
				let prevWardData = previousState.barData.find((i) => i.id == 1);
				if (!prevWardData) {
					barData.push(educationSectorData);
				} else {
					let prevWardDataIndex = previousState.barData.indexOf(prevWardData);
					barData[prevWardDataIndex] = educationSectorData;
				}
				return {
					barData,
				};
			});
		}
		if (
			nextProps.detail.selectedEducation !==
				this.props.detail.selectedEducation ||
			this.props.detail.selectedLanguage !== nextProps.detail.selectedLanguage
		) {
			let femaleData = nextProps.detail.selectedEducation?.data.female?.map(
				({ value }) => value
			);
			let maleData = nextProps.detail.selectedEducation?.data.male?.map(
				({ value }) => value
			);
			let thirdData = nextProps.detail.selectedEducation?.data.third?.map(
				({ value }) => value
			);
			let labelData = nextProps.detail.selectedEducation?.data.third?.map(
				(datum) => {
					return datum[nextProps.detail.selectedLanguage];
				}
			);
			let educationData = {
				id: 2,
				values: [
					{
						name: femaleTitle,
						data: femaleData,
					},
					{
						name: maleTitle,
						data: maleData,
					},
					{
						name: thirdTitle,
						data: thirdData,
					},
				],
				title:
					nextProps.detail.educationList.title[
						nextProps.detail.selectedLanguage
					],
				categories: labelData,
				detail: "Education",
				xaxis: "Education level",
				xaxis_nepali: "शैक्षिकस्तर",
			};
			this.setState((previousState) => {
				let barData = previousState.barData;
				let prevWardData = previousState.barData.find((i) => i.id == 2);
				if (!prevWardData) {
					barData.push(educationData);
				} else {
					let prevWardDataIndex = previousState.barData.indexOf(prevWardData);
					barData[prevWardDataIndex] = educationData;
				}
				return {
					barData,
				};
			});
		}
	}
	render() {
		return (
			<div className="row">
				{this.state.barData?.map((datum, index) => {
					return (
						<BarGraphCard key={index} datum={datum} section="education_bar_" />
					);
				})}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	detail: state.ward,
});
export default connect(mapStateToProps)(Education);

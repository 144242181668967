import React, { Component } from "react";
import { Button, Card, CardGroup, Modal } from "react-bootstrap";
import { Checkbox } from "@material-ui/core";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import L from "leaflet";
import { MapContainer, TileLayer, LayersControl, GeoJSON } from "react-leaflet";
import hash from "object-hash";
import Axios from "../../redux/axiosHandlerGeo";
import GeoJsonLayer from "./GeoJsonLayer";
import kanchanpur from "../../geojson/kanchanpur.json";
import { infastructure } from "../../variable/global";
// import kanchanpur from "../../geojson/bedkot.json";
import "leaflet/dist/leaflet.css";
import "../../assets/css/homePageDescription.scss";
import { connect } from "react-redux";

class EmergencyContactModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sections: [],
		};
	}
	componentDidMount() {
		let sections = [
			// {
			// 	title: "",
			// 	numbers: [
			// 		{
			// 			title: "बरुण यन्त्र (वडा नं. १८)",
			// 			value: "९७४९ ५१४०५५ , ०९९ -५२२३११",
			// 		},
			// 		{
			// 			title: "सेफर हस्पिटल, वडा नं.  ०४",
			// 			value: "०९९-५२०८०५",
			// 		},
			// 		{
			// 			title: "महाकाली अञ्चल अस्पताल, वडा नं. १८",
			// 			value: "०९९-५२११११",
			// 		},
			// 		{
			// 			title: "झलारी आयुर्बेद औषधालय	 शु.न.१० झलारी",
			// 			value: "०९९-५४०३१६",
			// 		},
			// 	],
			// },
			// {
			// 	title: "एम्बुलेन्स सेवा प्रदायक संस्था",
			// 	englishTitle: "Ambulance Service Provider",
			// 	numbers: [
			// 		{
			// 			title: "प्राथमिक स्वास्थ्य केन्द्र, श्रीगाउँ",
			// 			englishTitle: "Primary Health Center, Shreegaon",
			// 			value: "९८४७८५५८१५",
			// 			englishValue: "9847855815",
			// 		},
			// 		{
			// 			title: "हेकुली स्वास्थ्य चौकी",
			// 			englishTitle: "Hekuli Health Post",
			// 			value: "९८०३२४७०६३",
			// 			englishValue: "9803247063",
			// 		},
			// 		{
			// 			title: "गोल्टाकुरी स्वास्थ्य चौकी",
			// 			englishTitle: "Goltakuri Health Post",
			// 			value: "९८६१७१४७०४",
			// 			englishValue: "9861714704",
			// 		},
			// 	],
			// },
			{
				title: "",
				englishTitle: "",
				numbers: [
					{
						title: "प्रसुती केन्द्र, लिस्ती स्वास्थ चौकी",
						englishTitle: "Maternity Center, Listi Health Post",
						value: "९८५११५०८०४",
						englishValue: "9851150804",
					},
					{
						title: "हिन्दी हेल्थ सेन्ट",
						englishTitle: "Hindi Health Center",
						value: "९८४९१२५०४३",
						englishValue: "9849125043",
					},
					{
						title: "सीमा प्रहरी चौकी कोदारी",
						englishTitle: "Border Police Post Kodari",
						value: "९८५१२७९८५३",
						englishValue: "9851279853",
					},
					{
						title: "प्रहरी चौकी फुल्पिङ्कटी",
						englishTitle: "Fulpinkati police station",
						value: "९८५१२५३६८९ ",
						englishValue: "9851253689",
					},
					{
						title: "सैनिक व्यारेक",
						englishTitle: "Soldier barrack",
						value: "९८४१८६१७२५",
						englishValue: "9841861725",
					},
					{
						title: "गाउँपालिका",
						englishTitle: "Village municipality",
						value: "०१-१४८००१५, ०१-१४८००३४",
						englishValue: "01-1480015, 01-1480034",
					},
				],
			},
		];
		this.setState({ sections });
	}
	renderContacts() {
		return (
			<>
				{this.state.sections.map((section, secIndex) => {
					return (
						<div key={secIndex}>
							{section.title ? (
								<>
									<h3 className="font-weight-bolder mb-4 mt-4">
										{this.props.selectedLanguage == "nepali"
											? section.title
											: section.englishTitle}
									</h3>
								</>
							) : null}
							<div className="row">
								{section.numbers.map((number, numIndex) => {
									return (
										<div
											className="col-lg-6 d-flex flex-wrap mb-4"
											key={numIndex}
										>
											<h5 className="mr-2" id="modal-bold">
												{this.props.selectedLanguage == "nepali"
													? number.title
													: number.englishTitle}
												:{" "}
											</h5>
											<h5 className="text-primary font-weight-bold">
												{this.props.selectedLanguage == "nepali"
													? number.value
													: number.englishValue}
											</h5>
										</div>
									);
								})}
							</div>
						</div>
					);
				})}
			</>
		);
	}
	render() {
		return (
			<Modal
				{...this.props}
				size="xl"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				closeButton
			>
				<Modal.Body className="p-md-15">
					<div className="d-flex justify-content-between mb-6">
						<h1 className="font-weight-bolder text-danger">
							<i className="icon-emergency text-danger display-4"></i>
							{" "}{this.props.selectedLanguage == "nepali"
								? "आपतकालीन सम्पर्क"
								: "Emergency Contact"}
						</h1>
						<i className="fa fa-times" onClick={this.props.onHide}></i>
					</div>
					{this.renderContacts()}
				</Modal.Body>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => ({
	selectedLanguage: state.ward.selectedLanguage,
});
export default connect(mapStateToProps)(EmergencyContactModal);

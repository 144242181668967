import React, { Component } from "react";
import { Card } from "react-bootstrap";
import { connect } from "react-redux";
import { toAbsoluteUrl, toFormattedNumber } from "../../_metronic/_helpers";
class NumericStats extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cards: [],
		};
		this.getNumericCard = this.getNumericCard.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		// You don't have to do this check first, but it can help prevent an unneeded render
		if (
			(nextProps.detail !== this.props.detail ||
				nextProps.selectedLanguage !== this.props.selectedLanguage) &&
			nextProps.detail
		) {
			const cards = [
				{
					id: "1",
					title: nextProps.detail[0]
						? nextProps.detail[0][nextProps.selectedLanguage]
						: "",
					value: nextProps.detail[0]?.value,
					type: "numeric",
					icon: "media/hamropalika/home_icon.png",
				},
				{
					id: "2",
					title: nextProps.detail[1]
						? nextProps.detail[1][nextProps.selectedLanguage]
						: "",
					value: nextProps.detail[1]?.value,
					type: "numeric",
					icon: "media/hamropalika/people_icon.png",
				},
				{
					id: "3",
					title: "",
					type: "gender",
					icon: "",
					categories: [
						{
							id: "1",
							title: nextProps.detail[2]
								? nextProps.detail[2][nextProps.selectedLanguage]
								: "",
							value: nextProps.detail[2]?.value,
							icon: "media/hamropalika/male_icon.png",
						},
						{
							id: "2",
							title: nextProps.detail[3]
								? nextProps.detail[3][nextProps.selectedLanguage]
								: "",
							value: nextProps.detail[3]?.value,
							icon: "media/hamropalika/female_icon.png",
						},
						{
							id: "3",
							title: nextProps.detail[4]
								? nextProps.detail[4][nextProps.selectedLanguage]
								: "",
							value: nextProps.detail[4]?.value,
							icon: "media/hamropalika/third_gender.png",
						},
					],
				},
				{
					id: "4",
					type: "family",
					icon: "media/hamropalika/family_icon.png",
					values: [
						{
							id: "1",
							title: nextProps.detail[5]
								? nextProps.detail[5][nextProps.selectedLanguage]
								: "",
							value: nextProps.detail[5]?.value,
						},
					],
				},
			];
			this.setState({ cards });
		}
	}
	componentDidMount() {
		const cards = [
			{
				id: "1",
				title: this.props.detail[0]
					? this.props.detail[0][this.props.selectedLanguage]
					: "",
				value: this.props.detail[0]?.value,
				type: "numeric",
				icon: "media/hamropalika/home_icon.png",
			},
			{
				id: "2",
				title: this.props.detail[1]
					? this.props.detail[1][this.props.selectedLanguage]
					: "",
				value: this.props.detail[1]?.value,
				type: "numeric",
				icon: "media/hamropalika/people_icon.png",
			},
			{
				id: "3",
				title: "",
				type: "gender",
				icon: "",
				categories: [
					{
						id: "1",
						title: this.props.detail[2]
							? this.props.detail[2][this.props.selectedLanguage]
							: "",
						value: this.props.detail[2]?.value,
						icon: "media/hamropalika/male_icon.png",
					},
					{
						id: "2",
						title: this.props.detail[3]
							? this.props.detail[3][this.props.selectedLanguage]
							: "",
						value: this.props.detail[3]?.value,
						icon: "media/hamropalika/female_icon.png",
					},
					{
						id: "3",
						title: this.props.detail[4]
							? this.props.detail[4][this.props.selectedLanguage]
							: "",
						value: this.props.detail[4]?.value,
						icon: "media/hamropalika/third_gender.png",
					},
				],
			},
			{
				id: "4",
				type: "family",
				icon: "media/hamropalika/family_icon.png",
				values: [
					{
						id: "1",
						title: this.props.detail[5]
							? this.props.detail[5][this.props.selectedLanguage]
							: "",
						value: this.props.detail[5]?.value,
					},
				],
			},
		];
		this.setState({ cards });
	}
	getGenderCard(card) {
		return (
			<div className="col-lg-3 pb-5 d-flex align-items-stretch" key={card.id}>
				<Card>
					<Card.Body className="px-3 py-1 ">
						<div className="d-flex flex-wrap gender">
							{card.categories.map((category, index) => {
								return (
									<div className="d-flex gender-item mr-1" key={index}>
										<img
											className="numeric-icons mt-4"
											src={toAbsoluteUrl(category.icon)}
											alt=""
										/>
										<div className="pt-4 pl-3">
											<h4 className="font-weight-black">
												{toFormattedNumber(category.value)}
											</h4>
											<p className="homepage-heading">
												<b>{category.title}</b>
											</p>
										</div>
									</div>
								);
							})}
						</div>
					</Card.Body>
				</Card>
			</div>
		);
	}
	getNumericCard(card) {
		return (
			<div className="col-lg-3 pb-5 d-flex align-items-stretch" key={card.id}>
				<Card>
					<Card.Body className="px-3 py-1">
						<div className="d-flex numeric-stat">
							<img
								className="numeric-icons mt-4"
								src={toAbsoluteUrl(card.icon)}
								alt=""
							/>
							<div className=" pt-4 pl-4">
								<h2 className="pb-5 mb-6 homepage-heading">
									<b>{card.title}</b>
								</h2>
								<h3 className="font-weight-black">
									{toFormattedNumber(card.value)}
								</h3>
							</div>
						</div>
					</Card.Body>
				</Card>
			</div>
		);
	}
	getFamilyCard(card) {
		return (
			<div className="col-lg-3 pb-5 d-flex align-items-stretch" key={card.id}>
				<Card>
					<Card.Body className="px-3 py-1">
						<div className="d-flex family">
							<img
								className="numeric-icons mt-4"
								src={toAbsoluteUrl(card.icon)}
								alt=""
							/>
							<div className="pl-4 pt-4">
								{card.values.map((val, index) => {
									return (
										<div key={index}>
											<h2 className="pb-5 mb-6 homepage-heading">
												<b>{val.title}</b>
											</h2>
											<h3 className="font-weight-black">{val.value}</h3>
										</div>
									);
								})}
							</div>
						</div>
					</Card.Body>
				</Card>
			</div>
		);
	}
	render() {
		return (
			<>
				<div className="row">
					{this.state.cards.map((card) => {
						if (card.type == "numeric") {
							return this.getNumericCard(card);
						}
						if (card.type == "gender") {
							return this.getGenderCard(card);
						}
						if (card.type == "family") {
							return this.getFamilyCard(card);
						}
					})}
				</div>
			</>
		);
	}
}
const mapStateToProps = (state) => ({
	detail: state.ward.wardDetails?.data,
	selectedLanguage: state.ward.selectedLanguage,
});
export default connect(mapStateToProps)(NumericStats);

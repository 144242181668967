import React, { Component, useMemo } from "react";
import MunicipalityDescription from "../sections/MunicipalityDescription";
import NumericStats from "../sections/NumericStats";
import StatsGraphChart from "../sections/StatsGraphChart";
import StatsPieChart from "../sections/StatsPieChart";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import EconomicDetail from "../sections/EconomicDetail";
import FarmAnimal from "../sections/FarmAnimal";
import IndustrialBanking from "../sections/IndustrialBanking";
import Health from "../sections/Health";
import Education from "../sections/Education";
import Sanitation from "../sections/Sanitation";
import Buildings from "../sections/Buildings";
import MapSection from "../sections/MapSection";
import Representative from "../sections/Representative";
import Staff from "../sections/Staff";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

class HomePage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sectionTitles: [],
		};
	}
	componentWillReceiveProps(nextProps) {
		if (this.props.selectedLanguage !== nextProps.selectedLanguage) {
			let sectionTitles = [];
			if (nextProps.selectedLanguage == "nepali") {
				sectionTitles = [
					"जनसंख्या विवरण",
					"आर्थिक अवस्था विवरण",
					"कृषि चौपाया तथा पशुपन्छी विवरण",
					// "उद्योग, ब्यापार तथा बैंकिङ विवरण",
					"संस्थागत विवरण",
					"स्वास्थ्य  विवरण",
					"शैक्षिक संस्था विवरण",
					"खाने पानी तथा सरसफाई सम्बन्धी विवरण",
					"आवास तथा भवन सम्बन्धी विवरण",
					"जन प्रतिनिधि",
					"कर्मचारी",
				];
			} else {
				sectionTitles = [
					"Population Details",
					"Economic State Details",
					"Agricultural Livestock and Livestock Description",
					"Organization Details",
					"Health details",
					"Educational Institution Details",
					"Drinking Water and Sanitation Details",
					"Accommodation and building details",
					"People's Representatives",
					"Employees",
				];
			}
			this.setState({ sectionTitles });
		}
	}
	componentDidMount() {
		if (this.props.selectedLanguage) {
			let sectionTitles = [];
			if (this.props.selectedLanguage == "nepali") {
				sectionTitles = [
					"जनसंख्या विवरण",
					"आर्थिक अवस्था विवरण",
					"कृषि चौपाया तथा पशुपन्छी विवरण",
					// "उद्योग, ब्यापार तथा बैंकिङ विवरण",
					"संस्थागत विवरण",
					"स्वास्थ्य  विवरण",
					"शैक्षिक संस्था विवरण",
					"खाने पानी तथा सरसफाई सम्बन्धी विवरण",
					"आवास तथा भवन सम्बन्धी विवरण",
					"जन प्रतिनिधि",
					"कर्मचारी",
				];
			} else {
				sectionTitles = [
					"Population Details",
					"Economic State Details",
					"Agricultural Livestock and Livestock Description",
					"Organization Details",
					"Health details",
					"Educational Institution Details",
					"Drinking Water and Sanitation Details",
					"Accommodation and building details",
					"People's Representatives",
					"Employees",
				];
			}
			this.setState({ sectionTitles });
		}
	}
	render() {
		return (
			<>
				<section id="municipal_stats">
					<NumericStats />
					<MunicipalityDescription />
				</section>
				<section className="nav-content-item" id="numeric_stats">
					<div className="row">
						<div className="col-12">
							<div className="d-flex align-items-center my-4">
								{/* <SVG className="primary-icon" src={toAbsoluteUrl('media/hamropalika/svg/family.svg')} alt="" /> */}
								<i className="icon icon-population section-icon"></i>
								<h1 className="ml-3 mb-0 homepage-heading">
									{this.state.sectionTitles[0]}
								</h1>
							</div>
						</div>
					</div>
					<StatsPieChart />
					<StatsGraphChart />
				</section>
				<section className="nav-content-item" id="economic_stats">
					<div className="row">
						<div className="col-12">
							<div className="d-flex align-items-center my-4">
								{/* <SVG className="primary-icon" src={toAbsoluteUrl('media/hamropalika/svg/economic.svg')} alt="" /> */}
								<i className="icon icon-economy section-icon"></i>
								<h1 className="ml-3 mb-0 homepage-heading">
									{this.state.sectionTitles[1]}
								</h1>
							</div>
						</div>
					</div>
					<EconomicDetail />
				</section>
				<section className="nav-content-item" id="farm_animals">
					<div className="row">
						<div className="col-12">
							<div className="d-flex align-items-center my-4">
								{/* <SVG width="52" height="52" className="primary-icon" src={toAbsoluteUrl('media/hamropalika/svg/farm.svg')} alt="" /> */}
								<i className="icon icon-farm section-icon"></i>
								<h1 className="ml-3 mb-0 homepage-heading">
									{this.state.sectionTitles[2]}
								</h1>
							</div>
						</div>
					</div>
					<FarmAnimal />
				</section>
				<section className="nav-content-item" id="health">
					<div className="row">
						<div className="col-12">
							<div className="d-flex align-items-center my-4">
								{/* <SVG width="52" height="52" className="primary-icon" src={toAbsoluteUrl('media/hamropalika/svg/hospital.svg')} alt="" /> */}
								<i className="icon icon-hospital section-icon"></i>
								<h1 className="ml-3 mb-0 homepage-heading">
									{this.state.sectionTitles[4]}
								</h1>
							</div>
						</div>
					</div>
					<Health />
				</section>
				<section className="nav-content-item" id="education">
					<div className="row">
						<div className="col-12">
							<div className="d-flex align-items-center my-4">
								{/* <SVG width="52" height="52" className="primary-icon" src={toAbsoluteUrl('media/hamropalika/svg/open-book.svg')} alt="" /> */}
								<i className="icon icon-ic-borrowed-books section-icon"></i>
								<h1 className="ml-3 mb-0 homepage-heading">
									{this.state.sectionTitles[5]}
								</h1>
							</div>
						</div>
					</div>
					<Education />
				</section>
				<section className="nav-content-item" id="sanitation">
					<div className="row">
						<div className="col-12">
							<div className="d-flex align-items-center my-4">
								{/* <SVG width="52" height="52" className="primary-icon" src={toAbsoluteUrl('media/hamropalika/svg/safe.svg')} alt="" /> */}
								<i className="icon icon-safety section-icon"></i>
								<h1 className="ml-3 mb-0 homepage-heading">
									{this.state.sectionTitles[6]}
								</h1>
							</div>
						</div>
					</div>
					<Sanitation />
				</section>
				<section className="nav-content-item" id="buildings">
					<div className="row">
						<div className="col-12">
							<div className="d-flex align-items-center my-4">
								{/* <SVG width="52" height="52" className="primary-icon" src={toAbsoluteUrl('media/hamropalika/svg/home.svg')} alt="" /> */}
								<i className="icon icon-home section-icon"></i>
								<h1 className="ml-3 mb-0 homepage-heading">
									{this.state.sectionTitles[7]}
								</h1>
							</div>
						</div>
					</div>
					<Buildings />
				</section>
				<section className="nav-content-item mb-5" id="industrial_banking">
					<div className="row">
						<div className="col-12">
							<div className="d-flex align-items-center my-4">
								{/* <SVG width="52" height="52" className="primary-icon" src={toAbsoluteUrl('media/hamropalika/svg/deal.svg')} alt="" /> */}
								<i className="icon icon-banking section-icon"></i>
								<h1 className="ml-3 mb-0 homepage-heading">
									{this.state.sectionTitles[3]}
								</h1>
							</div>
						</div>
					</div>
					<IndustrialBanking />
				</section>
				<section className="nav-content-item" id="map">
					<MapSection />
				</section>
				<section className="nav-content-item" id="representative">
					<div className="row d-flex justify-content-center p-3">
						<div className="col-md-6 col-sm-4 order-md-1 order-1">
							<div className="d-flex align-items-center my-4">
								{/* <SVG width="52" height="52" className="primary-icon" src={toAbsoluteUrl('media/hamropalika/svg/deal.svg')} alt="" /> */}
								<i className="icon icon-banking section-icon"></i>
								<h1 className="ml-3 mb-0 homepage-heading">
									{this.state.sectionTitles[8]} / {this.state.sectionTitles[9]}
								</h1>
							</div>
						</div>
						{/* <div className="col-md-4 col-sm-4 order-md-2 order-2"> */}
						{/* <div className="d-flex align-items-center justify-content-md-end my-4">
								<i className="icon icon-employee section-icon"></i>
								<h1 className="ml-3 mb-0 homepage-heading">{this.state.sectionTitles[9]}</h1>
							</div> */}
						{/* </div> */}
						<div className="col-md-6 col-sm-4 order-md-3 order-3 align-self-center d-flex justify-content-md-end">
							<Link to={`/staff-detail`} state={this.props.selectedLanguage}>
								<button className="btn btn-primary font-weight-bold px-16 font-size-h6 mt-2 ">
									{this.props.selectedLanguage == "nepali"
										? "विवरण हेर्नुहोस"
										: "View details"}
								</button>
							</Link>
						</div>
					</div>

					<div className="col-md-12 mb-4">
						<Representative />
					</div>
				</section>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	selectedLanguage: state.ward.selectedLanguage,
});
export default connect(mapStateToProps)(HomePage);
